import React, { useState, useEffect, useRef } from "react";
import PageWrap from "./pagewrap.componant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faFacebookSquare, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import contactImg from "../assets/images/Woman_on_Phone-min.jpg"
import validateUtility from "../functions/validateUtility";
import ReCAPTCHA from "react-google-recaptcha";
export default function Contact() {
    const [formInput, setFormInput] = useState({ name: '', email: '', phone_number: '', subject: '', message: '' })
    const [formSupportInput, setFormSupportInput] = useState({ name: '', email: '', phone_number: '', subject: '', message: '' })
    const validated = useRef({ name: false, email: false, subject: false, message: false })
    const validated2 = useRef({ name: false, email: false, subject: false, message: false })
    const [trigger, setTrigger] = useState(false)
    const [trigger2, setTrigger2] = useState(false)
    const [popup, setPopup] = useState(false)
    const [supportForm, setSupportForm] = useState(false)
    const captchaRef = useRef(null)
    const captchaRef2 = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);
  
  

    function updateFormInput(key, value) {
        var temp = { ...formInput }
        temp[key] = value
        setFormInput(temp)
        if (key in validated.current) {
            console.log(validateUtility.regValidate(key, value))

            validated.current[key] = validateUtility.regValidate(key, value) ? true : false;
        }

    }

    function updateFormSupportInput(key, value) {
        var temp = { ...formSupportInput }
        temp[key] = value
        setFormSupportInput(temp)
        if (key in validated2.current) {
            console.log(validateUtility.regValidate(key, value))

            validated2.current[key] = validateUtility.regValidate(key, value) ? true : false;
        }

    }

    async function submitForm(event) {
        event.preventDefault();
    
        if (!(validated.current.name && validated.current.email && validated.current.subject && validated.current.message)) {
            setTrigger(true)
            return

        } else {
            const token = captchaRef.current.getValue();

            captchaRef.current.reset();
            if (!token) {
                setPopup({ subject: 'Oops', message: 'Please verify that you are not a robot' })
                return
            }
         
            formInput.access_key = '6776320e-d33e-4353-8648-d17ccaa8cabd'
        
            const json = JSON.stringify(formInput);

            const res = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: json
            }).then((res) => res.json());

            if (res.success) {
                console.log("Success", res);
            }
            setTrigger(false)
            setFormInput({ name: '', email: '', phone_number: '', subject: '', message: '' })
            validated.current = { name: false, email: false, subject: false, message: false }
            setPopup({ subject: 'Thanks for your message!', message: 'We will get back to you as soon as possible' })
        }
    }

    async function submitSupportForm(event) {
        event.preventDefault();
    
        if (!(validated2.current.name && validated2.current.email && validated2.current.subject && validated2.current.message)) {
            setTrigger2(true)
            return

        } else {
            const token = captchaRef2.current.getValue();

            captchaRef2.current.reset();
            if (!token) {
                setPopup({ subject: 'Oops', message: 'Please verify that you are not a robot' })
                return
            }
         
            formSupportInput.access_key = '6776320e-d33e-4353-8648-d17ccaa8cabd'
        
            const json = JSON.stringify(formSupportInput);

            const res = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: json
            }).then((res) => res.json());

            if (res.success) {
                console.log("Success", res);
            }
            setTrigger2(false)
            setFormSupportInput({ name: '', email: '', phone_number: '', subject: '', message: '' })
            setSupportForm(false)
            validated2.current = { name: false, email: false, subject: false, message: false }
            setPopup({ subject: 'Thanks for your request!', message: 'We will be in touch as soon as possible' })
        }
    }


    useEffect(() => {
        setTimeout(() => {
            setLoadedCaptcha(true);
        }, 1500);
    })

    return (
        <PageWrap>
            <div className="bg-hero-pattern bg-no-repeat bg-cover absolute w-full h-[300px] sm:h-[400px] xl:h-[475px]">
                <svg className="z-1 fill-white absolute bottom-[-1px] " viewBox="0 0 1440 126" preserveAspectRatio="xMinYMin meet">
                    <path d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
                </svg>
            </div>
            <div className="bg-white pb-20">
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-[1fr auto] max-w-[1400px] px-[10%] 2xl:px-0 gap-x-20 xl:gap-x-40 mx-auto">
                    <div className="lg:justify-self-end z-10 md:w-[450px] md:h-[450px] w-full aspect-square max-w-[450px] overflow-hidden rounded-bl-full rounded-br-full flex-none">
                        <img className=" h-full w-full object-cover" src={contactImg} alt="Woman on phone" />
                    </div>
                    <div className="max-w-[400px] lg:mt-10 z-10 row-span-2">
                        <div className="accent-bar" />
                        <h3 className="heading">Contact Us</h3>
                        <form onSubmit={submitForm}>
                            <div className="form-item">
                                <label htmlFor="name" className="form-label">Name*</label>
                                <input name="name" type="text" value={formInput.name} className={`form-input ${trigger && !validated.current.name ? 'error-border' : ''}`} onChange={(e) => updateFormInput("name", e.target.value)} placeholder="Contact Name"></input>
                            </div>
                            <div className="flex flex-col sm:flex-row gap-x-10">
                                <div className="form-item">
                                    <label htmlFor="email" className="form-label">Email*</label>
                                    <input name="email" type="text" value={formInput.email} className={`form-input ${trigger && !validated.current.email ? 'error-border' : ''}`} onChange={(e) => updateFormInput("email", e.target.value)} placeholder="Email Address"></input>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="number" className="form-label">Number</label>
                                    <input name="number" type="tel" value={formInput.phone_number} className="form-input" onChange={(e) => updateFormInput("phone_number", e.target.value)} placeholder="Phone Number"></input>
                                </div>
                            </div>
                            <div className="form-item">
                                <label htmlFor="subject" className="form-label">Subject*</label>
                                <input name="subject" type="text" value={formInput.subject} className={`form-input ${trigger && !validated.current.subject ? 'error-border' : ''}`} onChange={(e) => updateFormInput("subject", e.target.value)} placeholder="Message Subject"></input>
                            </div>
                            <div className="form-item">
                                <label htmlFor="message" className="form-label">Message*</label>
                                <textarea name="message" type="text" value={formInput.message} className={`form-input h-32 resize-none ${trigger && !validated.current.message ? 'error-border' : ''}`} onChange={(e) => updateFormInput("message", e.target.value)} placeholder="Type your question or message"></textarea>
                            </div>
                            {loadedCaptcha &&
                                <ReCAPTCHA
                                    className="pb-3"
                                    theme="light"
                                    sitekey={'6Ledg9wpAAAAAIBXbivSs68hI-UgLv0JGI_jCInP'}
                                    ref={captchaRef}
                                    onChange={null}
                                    asyncScriptOnLoad={null}
                                />
                            }
                            <button type="submit" className="btn-primary block">Send</button>
                        </form>
                    </div>
                    <div className="lg:place-self-end z-10">
                        <div className="flex flex-col lg:items-center md:w-[450px]  w-full  max-w-[450px] ">
                            {/* <h5 className="font-bold tracking-wider text-lg pb-5">Follow Us</h5> */}
                            <div>
                                <div className="flex flex-col items-start sm:items-center sm:flex-row gap-x-6 gap-y-6 pt-12 pb-10 lg:py-10">
                                    {/* <a href="mailto:supportmbai@mbaiforms.us" className="group"><FontAwesomeIcon className="fa-lg fa-solid pr-2 text-light-pink group-hover:text-mb-red duration-500" icon={faEnvelope} />supportmbai@mbaiforms.us</a> */}
                                    <span><FontAwesomeIcon className="fa-lg fa-solid pr-2 text-light-pink" icon={faPhone} />703 349-6405</span>
                                    <button onClick={()=>setSupportForm(true)} className="btn-secondary">Request Support</button>
                                </div>
                                <div className="flex gap-x-5 text-xl text-light-pink ">
                                    {/* <h5 className="font-bold tracking-wider text-lg pb-5">Follow Us</h5> */}
                                    <a href={"https://www.facebook.com"}>
                                        <FontAwesomeIcon className="hover:text-mb-red fa-lg duration-500" icon={faFacebookSquare} />
                                    </a>
                                    <a href={"https://www.facebook.com"}>
                                        <FontAwesomeIcon className="hover:text-mb-red fa-lg duration-500" icon={faLinkedin} />
                                    </a>
                                    <a href={"https://www.youtube.com/channel/UCAY-09bgzgzKhqlW8THqgkw"}>
                                        <FontAwesomeIcon className="hover:text-mb-red fa-lg duration-500" icon={faYoutube} />
                                    </a>
                                    <a href={"https://x.com/mbaiforms39598"}>
                                        <FontAwesomeIcon className="hover:text-mb-red fa-lg duration-500" icon={faTwitter} />
                                    </a>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {popup &&
                <>
                    <div className="backdrop" />
                    <div style={{zIndex:'60'}} className="popup">
                        <h5 className="subheading">{popup.subject}</h5>
                        <p className="font-semibold tracking-wide">{popup.message}</p>
                        <button onClick={() => setPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}

                {supportForm &&
                <>
                    <div className="backdrop" />
                    <div style={{scrollbarWidth: 'thin'}} className="popup max-h-[85vh] overflow-auto ">
                        <h5 className="subheading">Need help?</h5>
                        <p className="font-semibold tracking-wide text-left max-w-[550px]">Submit a request and our support team will reach out to you as soon as possible</p>
                        <form onSubmit={submitSupportForm}>
                            <div className="form-item">
                                <label htmlFor="name" className="form-label">Name*</label>
                                <input name="name" type="text" value={formSupportInput.name} className={`form-input ${trigger2 && !validated2.current.name ? 'error-border' : ''}`} onChange={(e) => updateFormSupportInput("name", e.target.value)} placeholder="Contact Name"></input>
                            </div>
                            <div className="flex flex-col sm:flex-row gap-x-10">
                                <div className="form-item">
                                    <label htmlFor="email" className="form-label">Email*</label>
                                    <input name="email" type="text" value={formSupportInput.email} className={`form-input ${trigger2 && !validated2.current.email ? 'error-border' : ''}`} onChange={(e) => updateFormSupportInput("email", e.target.value)} placeholder="Email Address"></input>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="number" className="form-label">Number</label>
                                    <input name="number" type="tel" value={formSupportInput.phone_number} className="form-input" onChange={(e) => updateFormSupportInput("phone_number", e.target.value)} placeholder="Phone Number"></input>
                                </div>
                            </div>
                            <div className="form-item">
                                <label htmlFor="subject" className="form-label">Category*</label>
                                <select name="subject" value={formSupportInput.subject} className={`form-input ${trigger2 && !validated2.current.subject ? 'error-border' : ''}`} onChange={(e) => updateFormSupportInput("subject", e.target.value)} placeholder="Message Subject">
                                    <option value={''}>Select</option>
                                    <option value={'Billing'}>Billing</option>
                                    <option value={'Tech'} >Tech Support</option>
                                    <option value={'Other'}>Other</option>
                                </select>
                            </div>
                            <div className="form-item">
                                <label htmlFor="message" className="form-label">Message*</label>
                                <textarea name="message" type="text" value={formSupportInput.message} className={`form-input h-32 resize-none ${trigger2 && !validated2.current.message ? 'error-border' : ''}`} onChange={(e) => updateFormSupportInput("message", e.target.value)} placeholder="Tell us more about the problem you are having"></textarea>
                            </div>
                            {loadedCaptcha &&
                                <ReCAPTCHA
                                    className="pb-3"
                                    theme="light"
                                    sitekey={'6Ledg9wpAAAAAIBXbivSs68hI-UgLv0JGI_jCInP'}
                                    ref={captchaRef2}
                                    onChange={null}
                                    asyncScriptOnLoad={null}
                                />
                            }
                            <div className="flex gap-x-3 justify-end">
                            <button onClick={() => setSupportForm(false)} className="btn-secondary">Cancel</button>
                            <button type="submit" className="btn-primary block">Submit</button>
                            </div>
                        </form>
                        
                    </div>
                </>}

        </PageWrap>
    )
}