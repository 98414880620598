
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import logo from "../assets/images/logos/MBAIForms_logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faBars, faXmark } from "@fortawesome/free-solid-svg-icons";



export default function Header() {
    const [opacity, setOpacity] = useState(0)
    const [height, setHeight] = useState(100)
    const [path, setPath] = useState(window.location.pathname);
    const location = useLocation();
    const [windowSize, setWindowSize] = React.useState(window.innerWidth);
    const [headerPosition, setHeaderPosition] = useState(-100)
    const [headerTiming, setHeaderTiming] = useState(.8)
    const [headerY, setHeaderY] = useState(0)

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);


        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, []);


    const onWindowResize = () => {
        setWindowSize(window.innerWidth);
    };

    useEffect(() => {
        setPath(window.location.pathname)
    }, [location])

    const currentPathRef = useRef(window.location.pathname);

    if (location.pathname !== currentPathRef.current) {
        window.scrollTo(0, 0);
        currentPathRef.current = location.pathname;
    }

    useEffect(() => {
        document.addEventListener("scroll", () => {
            setOpacity(Math.min(window.scrollY / 50, 0.95))
            setHeight(Math.max(100 - window.scrollY, 70))

        })

    }, [])

    function navClick() {
        setHeaderPosition(-100);
        setHeaderTiming(0);
        setTimeout(() => { setHeaderTiming(.8) }, 100);
        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'auto';
        }
    }



    return (
        <>
            {windowSize > 1100 ?
                <div style={{ height: height, gridTemplateColumns: '1fr 50% 1fr' }} className="fixed w-full z-30 bg-light-grey grid items-center">
                    <div style={{ opacity: opacity }} className="z-20 transition-opacity duration-700 ease-in-out w-full h-full absolute bg-white " />
                    <img src={logo} className="h-16 z-30"></img>
                    <div className="flex place-self-center">


                        <nav className={`z-40 relative ease-in-out flex justify-center items-center gap-x-12 child:tracking-wider child-hover:text-light-pink child:font-bold child-hover:duration-100 border-opacity-50 border-white`}>

                            <Link style={{ transition: 'all .5s ease-in-out' }} className={`mb-[0.5%] ${path === "/" ? 'active-page' : ''}`} to={'/'}>Home</Link>
                            <Link style={{ transition: 'all .5s ease-in-out' }} className={`mb-[0.5%] ${path === "/about" ? 'active-page' : ''}`} to={'/about'}>About</Link>
                            <Link style={{ transition: 'all .5s ease-in-out' }} className={`mb-[0.5%] ${path === "/announcements" ? 'active-page' : ''}`} to={'/announcements'}>Announcements</Link>
                            <Link style={{ transition: 'all .5s ease-in-out' }} className={`mb-[0.5%] ${path === "/contact" ? 'active-page' : ''}`} to={'/contact'}>Contact Us</Link>

                        </nav>
                    </div>

                    <div style={{alignItems: 'center'}} className="place-self-end z-50 h-fit self-center flex place-content-center pr-3 gap-4">
                        <a href="http://www.mbaiforms.us/">
                        <button className="btn-secondary">Log In</button>
                        </a>
                        <Link to={"/subscribe"}>
                        <button className="btn-primary">Pricing</button>
                        </Link>
                    </div>
                </div>
                :
                <>
                    <div style={{ height: height }} className="fixed w-full z-30 bg-light-grey grid sm:grid-cols-3 grid-cols-[1fr_50%_1fr] items-center">
                        <div style={{ opacity: headerPosition ? opacity : '0' }} className="z-20 transition-opacity duration-700 ease-in-out w-full h-full absolute bg-white " />
                        {headerPosition === 0 ?
                            <FontAwesomeIcon onClick={() => { setHeaderPosition(-100); if (typeof window != 'undefined' && window.document) { document.body.style.overflow = 'auto';} }} className="z-30 text-2xl px-4 text-mb-red cursor-pointer" icon={faXmark} />
                            :
                            <FontAwesomeIcon onClick={() => { setHeaderPosition(0); if (typeof window != 'undefined' && window.document) {document.body.style.overflow = 'hidden';} }} className="z-30 text-2xl px-4 text-mb-red cursor-pointer" icon={faBars} />
                        }
                        <div className="flex ">
                            <img src={logo} className="z-30 place-self-center "></img>
                        </div>

                    </div>
                    <nav style={{ transition: `translate ${headerTiming}s cubic-bezier(0, .52, 0, 1)`, translate: `${headerPosition}vw`, top: height }} className={`flex flex-col gap-10 px-6 py-9 bg-[#f9f9f9] fixed z-20 w-full h-full translate-x-[${headerPosition}vw] child:tracking-wider child:font-bold`}>
                        <Link onClick={() => { navClick() }} className={` ${path === "/" ? 'active-page' : ''}`} to={'/'}>Home {path !== '/' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <Link onClick={() => { navClick() }} className={` ${path === "/about" ? 'active-page' : ''}`} to={'/about'}>About {path !== '/about' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <Link onClick={() => { navClick() }} className={` ${path === "/announcements" ? 'active-page' : ''}`} to={'/announcements'}>Announcements {path !== '/announcements' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <Link onClick={() => { navClick() }} className={` ${path === "/contact" ? 'active-page' : ''}`} to={'/contact'}>Contact Us {path !== '/contact' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <Link onClick={() => { navClick() }} className={` ${path === "/subscribe" ? 'active-page' : ''}`} to={'/subscribe'}>Subscribe {path !== '/subscribe' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</Link>
                        <a onClick={() => { navClick() }} className={` ${path === "/log-in" ? 'active-page' : ''}`} href="http://www.mbaiforms.us/">Log In {path !== '/log-in' ? <FontAwesomeIcon className="pl-4" icon={faAngleRight} /> : ''}</a>

                    </nav>

                </>
            }
        </>
    )
}