import logo from './logo.svg';
import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Homepage from './components/homepage.component';
import About from './components/about.component';
import Header from './components/header.component';
import Footer from './components/footer.component';
import Contact from './components/contact.component';
import Announcements from './components/announcements.component';
import Subscribe from './components/subscribe.component';
import Faq from './components/faq.component';
import PrivacyPolicy from './components/privacy.component';
import Tos from './components/tos.componant';
import Error from './components/error.component';

function App() {
  return (

    <div className="body">

      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="*" element={<Error />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/announcements" element={<Announcements />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<Tos />} />
        </Routes>
        <Footer/>
      </BrowserRouter>

    </div>
  );
}

export default App;
