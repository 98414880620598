import { Link } from "react-router-dom";
import PageWrap from "./pagewrap.componant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function Error() {
    return (
        <PageWrap>
            <div className="max-w-[1200px] px-[5%] mx-auto relative mb-20 ">

                <div className="accent-bar" />
                <h4 className="heading mb-4">Page Not Found</h4>
                <span className="font-semibold">Sorry, the page you requested cannot be found</span>
                <Link className="pt-12 block mx-auto" to={'/'}>Go to Homepage<FontAwesomeIcon className="pl-2" icon={faArrowRight} /></Link>
            </div>
        </PageWrap>
    )
}