

import React, { useRef, useState } from "react";
import filingImg from "../assets/images/professional-using-computer-min.png"
import nar from "../assets/images/logos/NAR_FullLockup_White.png"
import maryland from "../assets/images/logos/HomepageLogo.png"
import baltimore from "../assets/images/logos/Greater-Baltimore-Board-of-Realtors.png"
import remax from "../assets/images/logos/REMAX_logo.svg.png"
import execuhome from "../assets/images/logos/ExecuHome-Logo-SIDE-Transparent.png"
import c21 from "../assets/images/logos/Century_21_logo_2018.svg.png"
import coldwell from "../assets/images/logos/Coldwell_banker_logo_PNG1-removebg-preview.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import PageWrap from "./pagewrap.componant";
import { faPaperPlane, faPen, faQuoteLeft, faQuoteRight, faSearch, faShare, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import handshake from "../assets/images/Two_people_handshaking_2-min.jpg"
import avatar1 from "../assets/images/avatar1.png"
import avatar2 from "../assets/images/avatar2.png"
import avatar3 from "../assets/images/avatar3.png"
import { Link } from "react-router-dom";




export default function Homepage() {
    const brandLogosStatic = [nar, maryland, baltimore]
    const brandLogos = [coldwell, c21, execuhome, remax]
    const videoRef = useRef()
    const [selectedQuote, setSelectedQuote] = useState(0)
    const quotes = [
        { quote: "We have MBAI Forms for my brokerage and its so easy to use, easy to edit, easy to find information and helps me save time and effort in document preparation. It was very easy to learn, set up, and integrate into my business process. It helps me keep client files clean and simple so you always know where you are in a transaction. Too Good!!!", name: 'Paul Buckmaster', avatar: avatar1, title: 'Associate Broker', association: 'Keller Williams Lucido Agency' }
        // { quote: "Really loved working with them", name: 'Bob Agent', avatar: avatar3, title: 'Broker' },
        // { quote: "Very good business", name: 'Mary Realtor', avatar: avatar2, title: 'Agent' }
    ]



    return (
        <PageWrap>

            <div className="relative bg-hero-pattern bg-no-repeat bg-cover min-h-[85vh] sm:min-h-[90vh]  ">
                <div className="relative flex flex-col lg:flex-row max-w-[1400px] mx-auto px-[5%] 2xl:px-0 sm:min-h-[90vh]">
                    <div className="py-12 sm:py-24 lg:py-40 ">
                        <h2 className="font-header font-bold text-3xl text-black pb-6 tracking-wider">Online Forms <span className="font-script text-5xl pr-3">Tailored</span> to Your Business</h2>
                        <p className=" font-bold tracking-wide leading-relaxed">Your friendly alternative for real estate forms to streamline your workflow and maximise productivity</p>
                        <button onClick={() => videoRef.current.scrollIntoView()} className="bg-logo-pink hover:bg-mb-red px-5 py-2 rounded-full text-white mt-9 font-semibold">View Demo</button>

                    </div>
                    <div className="w-full sm:w-3/4  md:w-[60%] max-w-[700px] sm:min-w-[500px] place-self-end self-end mb-14">
                        <img src={filingImg} className="w-full" alt="Professional using computer" />
                    </div>


                </div>
                <svg className="z-1 fill-white absolute bottom-[-1px] " viewBox="0 0 1440 126" preserveAspectRatio="xMinYMin meet">
                    <path d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
                </svg>
            </div>

            <div className=" bg-white ">
                <div className="flex max-w-[1300px] mx-auto px-[5%] pb-20 gap-x-24 gap-y-12 flex-col-reverse lg:flex-row">
                    <div className=" md:w-[450px] md:h-[450px] w-full aspect-square mx-auto max-w-[450px] overflow-hidden rounded-tl-full rounded-tr-full flex-none">
                        <img className="h-full w-full object-cover object-85-100" src={handshake} alt="People shaking hands" />
                    </div>
                    <div className="">
                        <div className="h-2 w-32 bg-logo-pink mt-16"></div>
                        <h3 className="font-header text-black font-bold  tracking-wider text-3xl mt-5 pb-6">Service You Can <span className="font-script text-5xl ">Rely</span> On</h3>
                        <p className=" font-semibold tracking-wide leading-relaxed">MBAIForms is proud to have provided resources for Maryland realtors for over 30 years. As the industry has changed, we have adapted along with it without losing focus on customer service. Still a small business, we pride ourselves on maintaining a high level of one-on-one customer support and availability to our clients.</p>


                    </div>

                </div>
            </div>

            <div className="relative">
                <svg className="z-10 fill-white absolute bottom-[100px] " viewBox="0 0 1440 126" preserveAspectRatio="xMinYMin meet">
                    <path d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
                </svg>
                <div className="max-w-[1300px] mx-auto px-[5%] ">


                    <div className="h-2 w-32 bg-logo-pink mt-20"></div>
                    <h3 className="font-header text-black font-bold  tracking-wider text-3xl mt-5 pb-6">We Keep it <span className="font-script text-5xl pr-3">Simple</span></h3>
                    <p className=" font-bold tracking-wide pb-10">No headaches or steep learning curves here&#8212;get started as soon as you join. Don't pay for extra features you don't need.</p>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-11 gap-y-14 my-[7%] mx-auto mb-[-150px] child:max-w-[475px] justify-items-center">

                        <div className=" grid grid-rows-info-tile gap-8 p-10 bg-white rounded-md shadow-md relative min-h-80 max-w-lg z-[15]">
                            <div className="rounded-full p-4 bg-logo-pink justify-self-start self-start absolute top-[-30px] left-[50%] translate-x-[-50%]">
                                <FontAwesomeIcon transform={{ rotate: 90 }} className="text-white fa-2x" icon={faSearch} />
                            </div>
                            <span className="font-bold text-xl self-end">Select Your Document Type</span>
                            <span className="font-semibold text-med-grey leading-relaxed">Choose from a list of relevant forms depending on the type of document you need</span>

                        </div>
                        <div className="grid grid-rows-info-tile gap-5 p-10 bg-white rounded-md shadow-md relative min-h-80 max-w-lg z-[15]">
                            <div className="rounded-full p-4 bg-logo-pink justify-self-start self-start absolute top-[-30px] left-[50%] translate-x-[-50%]">
                                <FontAwesomeIcon className="text-white fa-2x" icon={faPen} />
                            </div>
                            <span className="font-bold text-xl self-end">Fill in your information</span>
                            <span className="font-semibold text-med-grey leading-relaxed">Save time by storing your commonly used information</span>

                        </div>
                        <div className="grid grid-rows-info-tile gap-5 p-10 bg-white rounded-md relative shadow-md min-h-80 max-w-lg z-[15]">
                            <div className="rounded-full p-4 bg-logo-pink justify-self-start self-start absolute top-[-30px] left-[50%] translate-x-[-50%]">
                                <FontAwesomeIcon className="text-white fa-2x" icon={faPaperPlane} />
                            </div>
                            <span className="font-bold text-xl self-end">Use Your Form!</span>
                            <span className="font-semibold text-med-grey leading-relaxed">Create a PDF, print, or share your newly created document</span>

                        </div>

                    </div>
                </div>
            </div>

            <div className="bg-white min-h-[250px] "></div>

            {/* <div className='bg-black pt-12  '>
                <h3 className=" font-header font-bold text-center tracking-wider text-xl text-white">Our Partners</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-16 px-[15%] sm:px-[10%] xl:px-[5%] mx-auto max-w-[1300px] pt-12 pb-12 md:py-24">
                    {brandLogosStatic.map((logo) =>
                        <img src={logo} className="h-[75px] md:h-[50px] lg:h-[75px] self-center justify-self-center" />
                    )}
                </div>


            </div> */}


            <div className=' w-full pb-10 bg-white'>
                <h3 className=" font-header font-bold text-center tracking-wider text-xl">Trusted By</h3>

                <div className='mx-auto max-w-[1300px] px-[15%] sm:px-[10%] xl:px-[5%] h-[30vh]' >
                    <Swiper
                        style={{ "--swiper-pagination-color": "rgb(71, 71, 71)" }}
                        slidesPerView={1}
                        // spaceBetween={50}
                        pagination={{
                            clickable: true,
                        }}
                        loop={true}
                        loopPreventsSliding={true}
                        loopedslides={brandLogos.length - 3}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: true,
                        }}
                        modules={[Pagination, Autoplay]}
                        breakpoints={{

                            375: {
                                spaceBetween: 50,
                                slidesPerView: 2
                            },
                            550: {
                                spaceBetween: 75,
                                slidesPerView: 2
                            },
                            750: {
                                spaceBetween: 150,
                                slidesPerView: 3
                            },
                            1200: {
                                spaceBetween: 200,
                                slidesPerView: 3

                            },
                            1600: {
                                spaceBetween: 250,
                                slidesPerView: 3

                            }
                        }}
                    >

                        {
                            brandLogos.map((logo, index) =>
                                <SwiperSlide key={index} >
                                    <div className='swipe-slide' >
                                        <img alt="Company logo" src={logo} style={{ width: '100%' }} />
                                    </div>
                                </SwiperSlide>


                            )
                        }

                    </Swiper>
                </div>

            </div>
            <div>
                <h3 className=" font-header font-bold text-center tracking-wider text-xl pt-16 md:pt-20">What our clients are saying</h3>

                <div className="max-w-[800px] w-[75%] mx-auto my-12">
                    <div style={{ gridTemplateRows: 'auto 1fr' }} className="w-full min-h-[300px] rounded-lg bg-black relative grid">
                        <div style={{ gridTemplateColumns: 'auto 1fr auto' }} className="md:grid text-white items-center px-11 pt-11">
                            <FontAwesomeIcon className="md:text-6xl md:pr-6 text-4xl pb-2" icon={faQuoteLeft} />
                            <p className="text-white sm:text-xl ">{quotes[selectedQuote]?.quote}</p>
                            <FontAwesomeIcon className="hidden md:text-6xl md:pl-6 md:block" icon={faQuoteRight} />
                        </div>
                        <div className="mx-11 py-5 min-h-12 text-right self-end justify-self-end">
                            <p className="text-white text-xl font-semibold">{quotes[selectedQuote]?.name}</p>
                            <p className="text-med-grey">{quotes[selectedQuote]?.title}{quotes[selectedQuote]?.association ? ', ' : ''}{quotes[selectedQuote]?.association}</p>


                        </div>
                    </div>
                    <div style={{ gridTemplateColumns: `repeat(${quotes?.length}, 1fr)` }} className={`md:w-[65%] w-[100%] mx-auto grid justify-items-center`}>

                        {quotes.map((quote, index) =>
                            <>
                                {selectedQuote === index ?
                                    <div style={{ gridColumn: index + 1 }} className={`arrow-down mt-[-1px]`} />
                                    : null}
                                <div style={{ gridColumn: index + 1, gridRow: 2 }} onClick={() => setSelectedQuote(index)} className={`self-center box-content rounded-full overflow-hidden cursor-pointer ${selectedQuote === index ? 'border-4 border-black' : ''} h-16 w-16 sm:h-20 sm:w-20 mt-2`}>
                                    <img className="object-fit-cover w-full h-full" src={quote.avatar}></img>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </div>
            <div ref={videoRef} className="px-[5%] py-12 w-full">
                <h3 className=" font-header font-bold text-center tracking-wider text-xl">Get a quick overview of our services by watching our demo:</h3>
                <div className='video-iframe' >

                    <iframe allowFullScreen="true" loading='lazy' width="100%" height="100%" src="https://www.youtube.com/embed/Ld9eioG0Noo?si=0mJL9-p4Ch8QAEpo&rel=0" title="MBAI Forms Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" referrerpolicy="strict-origin-when-cross-origin"></iframe>
                </div>

            </div>
            <div className="mx-[5%]">
                <div className="px-[5%] py-[5%] gap-x-[5%] gap-y-[30px] max-w-[1300px] bg-white mx-auto rounded-md mb-12 flex items-center justify-center">
                    <FontAwesomeIcon icon={faShieldHalved} className='text-black fa-5x' />
                    <div>
                        <h3 className='font-bold text-lg pb-[20px]'>We Take Your Security Seriously</h3>
                        <p style={{ maxWidth: '800px' }} className='pb-[20px] text-med-grey text-left font-semibold'>
                            We value you trusting us with your information. MBAI Forms will never sell or share your data with anyone, and we take every measure to keep your data as secure as possible online.
                        </p>
                        {/* <span className=" cursor-pointer">Read our privacy policy <Link to={'privacy-policy'}>here</Link></span> */}

                    </div>
                </div>


            </div>
            <div className="maryland-permission">
                <p>Membership in Maryland Realtors® required</p>
            </div>
            <div className='bg-black pt-12  '>
                <h3 className=" font-header font-bold text-center tracking-wider text-xl text-white">Our Partners</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-x-12 gap-y-16 px-[15%] sm:px-[10%] xl:px-[5%] mx-auto max-w-[1300px] pt-12 pb-12 md:py-24">
                    {brandLogosStatic.map((logo) =>
                        <img src={logo} className="h-[75px] md:h-[50px] lg:h-[75px] self-center justify-self-center" />
                    )}
                </div>


            </div>


        </PageWrap>
    )
}