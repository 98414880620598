import PageWrap from "./pagewrap.componant";
import validateUtility from "../functions/validateUtility";
import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingUser, faClock, faCreditCard, faPaperPlane, faPen, faSearch, faShare, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
export default function Subscribe() {
    const [formInput, setFormInput] = useState({ name: '', email: '', phone_number: '', company: '', nrds: '' })
    const validated = useRef({ name: false, email: false, company: false, nrds: false })
    const [trigger, setTrigger] = useState(false)
    const [popup, setPopup] = useState(false)
    function updateFormInput(key, value) {
        var temp = { ...formInput }
        temp[key] = value
        setFormInput(temp)
        if (key in validated.current) {
            console.log(key)

            validated.current[key] = validateUtility.regValidate(key, value) ? true : false;
        }

    }
    async function submitForm(event) {
        event.preventDefault();

        if (!(validated.current.name && validated.current.email && validated.current.company && validated.current.nrds)) {
            setTrigger(true)
            return
        } else {
            const token = captchaRef.current.getValue();

            captchaRef.current.reset();
            if (!token) {
                setPopup({ subject: 'Oops', message: 'Please verify that you are not a robot' })
                return
            }

            formInput.access_key = '6776320e-d33e-4353-8648-d17ccaa8cabd'
            const json = JSON.stringify(formInput);

            const res = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: json
            }).then((res) => res.json());

            if (res.success) {
                console.log("Success", res);
            }
            setTrigger(false)
            setFormInput({ name: '', email: '', phone_number: '', company: '', nrds: '' })
            validated.current = { name: false, email: false, company: false, nrds: false }
            setPopup({ subject: 'Thanks for your interest!', message: 'We will verify your realtor status and get back to you within 2 business days' })
        }

    }

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoadedCaptcha(true);
        }, 1500);
    })


    return (
        <PageWrap>
            <div className="relative">
                <svg className="z-10 fill-white absolute bottom-[100px] " viewBox="0 0 1440 126" preserveAspectRatio="xMinYMin meet">
                    <path d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
                </svg>
                <div className="max-w-[1300px] mx-auto px-[5%] ">


                    <div className="h-2 w-32 bg-logo-pink mt-20"></div>
                    <h3 className="font-header text-black font-bold  tracking-wider text-3xl mt-5 pb-6">Affordable and <span className="font-script text-5xl pr-3">Flexible</span></h3>
                    <p className=" font-bold tracking-wide pb-10">It's easy to get started and completely flexible to your needs</p>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-14 gap-y-14 my-[7%] mx-auto mb-[-150px] justify-items-center child:max-w-[475px]">
                        <div style={{ gridTemplateRows: 'auto auto auto auto 1fr' }} className="justify-items-center w-full grid grid-cols-1 gap-y-4 duration-500 ease-in-out border-t-[12px] border-med-blue rounded-md p-5 z-[15] min-h-80 shadow-md bg-white ">
                            <FontAwesomeIcon className="text-med-blue fa-2x " icon={faUser} />
                            <span className="self-start text-2xl text-center font-extrabold">Agents</span>
                            <span className="font-bold text-lg">Includes:</span>
                            <div >
                                <ul

                                    style={{ listStyleType: "disc" }}
                                >
                                    <li>Access to Digital Library of Forms</li>
                                    <li>Unlimited Transactions</li>
                                    <li>Customize Fillable Forms</li>
                                    <li>Secured Backup</li>
                                    <li>Sign and get Signatures</li>
                                    <li>Combine and Add Addendums</li>
                                    <li>Easy to Use and Navigate</li>
                                    <li>Premium Support</li>

                                </ul>
                            </div>
                            <div className="self-end mt-2 flex items-center justify-center gap-x-[20px] border-[3px] border-med-blue/20 rounded-full w-fit mx-auto px-5 py-1">
                                <div className="flex flex-col items-center">
                                    <h5 className="font-bold text-lg">$22 usd </h5>
                                    <h6 className=" text-med-grey font-bold" >monthly</h6>
                                </div>
                                <span className="text-med-grey font-semibold">or</span>
                                <div className="flex flex-col items-center">
                                    <h5 className="font-bold text-lg">$134 usd </h5>
                                    <h6 className="font-bold text-med-grey " >yearly</h6>
                                </div>
                            </div>


                        </div>
                        <div style={{ gridTemplateRows: 'auto auto auto auto 1fr' }} className="justify-items-center w-full grid grid-cols-1 flex-col gap-y-4 items-center duration-500 ease-in-out border-t-[12px] border-logo-pink rounded-md p-5 z-[15] min-h-80 shadow-md bg-white ">
                            <FontAwesomeIcon className="text-logo-pink fa-2x " icon={faUsers} />
                            <span className="text-2xl text-center font-extrabold">Teams</span>
                            <span className="font-bold text-lg">Includes:</span>
                            <div >
                                <ul

                                    style={{ listStyleType: "disc" }}
                                >
                                    <li>Access to Digital Library of Forms</li>
                                    <li>Unlimited Transactions</li>
                                    <li>Customize Fillable Forms</li>
                                    <li>Secured Backup</li>
                                    <li>Sign and get Signatures</li>
                                    <li>Combine and Add Addendums</li>
                                    <li>Easy to Use and Navigate</li>
                                    <li>Premium Support</li>
                                    <li>Agent Oversight</li>
                                    <li>Custom Forms</li>
                                    <li>Manage your Agents</li>
                                    <li>Custom Branding</li>
                                    <li>Premium Support</li>

                                </ul>
                            </div>
                            <Link className="self-end" to={'/contact'}>
                                <button className="hover:border-logo-pink/50 duration-700 mt-2 font-bold border-[3px] border-logo-pink/20 rounded-full w-fit px-5 py-4">Contact Us for Pricing</button>
                            </Link>
                        </div>
                        <div style={{ gridTemplateRows: 'auto auto auto auto 1fr' }} className="w-full justify-items-center grid grid-cols-1 gap-y-4 items-center duration-500 ease-in-out border-t-[12px] border-yellow rounded-md p-5 z-[15] min-h-80 shadow-md bg-white ">
                            <FontAwesomeIcon className="text-yellow fa-2x " icon={faBuildingUser} />
                            <span className="text-2xl text-center font-extrabold">Brokerages</span>
                            <span className="font-bold text-lg">Includes:</span>
                            <div >
                                <ul

                                    style={{ listStyleType: "disc" }}
                                >
                                    <li>Access to Digital Library of Forms</li>
                                    <li>Unlimited Transactions</li>
                                    <li>Customize Fillable Forms</li>
                                    <li>Secured Backup</li>
                                    <li>Sign and get Signatures</li>
                                    <li>Combine and Add Addendums</li>
                                    <li>Easy to Use and Navigate</li>
                                    <li>Premium Support</li>
                                    <li>Agent Oversight</li>
                                    <li>Custom Forms</li>
                                    <li>Manage your Agents</li>
                                    <li>Custom Branding</li>
                                    <li>Premium Support</li>
                                </ul>
                            </div>
                            <Link className="self-end" to={'/contact'}>
                                <button className="hover:border-yellow/50 duration-700 mt-2 font-bold border-[3px] border-yellow/20 rounded-full w-fit px-5 py-4">Contact Us for Pricing</button>
                            </Link>

                        </div>


                        {/* <div className=" grid grid-rows-info-tile gap-8 p-10 bg-white rounded-md shadow-md relative min-h-80 max-w-lg z-[15]">
                            <div className="rounded-full p-4 bg-logo-pink justify-self-start self-start absolute top-[-30px] left-[50%] translate-x-[-50%]">
                                <FontAwesomeIcon className="text-white fa-2x" icon={faPen} />
                            </div>
                            <span className="font-bold text-xl self-end">Fill In Your Details</span>
                            <span className="font-semibold text-med-grey leading-relaxed">Send us your information and we will add you to our database and verify your Maryland Realtors membership</span>

                        </div>
                        <div className="grid grid-rows-info-tile gap-5 p-10 bg-white rounded-md shadow-md relative min-h-80 max-w-lg z-[15]">
                            <div className="rounded-full p-4 bg-logo-pink justify-self-start self-start absolute top-[-30px] left-[50%] translate-x-[-50%]">
                                <FontAwesomeIcon className="text-white fa-2x" icon={faClock} />
                            </div>
                            <span className="font-bold text-xl self-end">Select Your Payment Plan</span>
                            <span className="font-semibold text-med-grey leading-relaxed">Choose what works best for you&#8212;monthly, quarterly, or yearly payments </span>

                        </div>
                        <div className="grid grid-rows-info-tile gap-5 p-10 bg-white rounded-md relative shadow-md min-h-80 max-w-lg z-[15]">
                            <div className="rounded-full p-4 bg-logo-pink justify-self-start self-start absolute top-[-30px] left-[50%] translate-x-[-50%]">
                                <FontAwesomeIcon className="text-white fa-2x" icon={faCreditCard} />
                            </div>
                            <span className="font-bold text-xl self-end">Log In and Complete Payment</span>
                            <span className="font-semibold text-med-grey leading-relaxed">Once we have verified your membership, we will send you a temporary password so you can log in and finish setting up your account</span>

                        </div> */}

                    </div>
                </div>
            </div>
            {/* <div className="bg-white bg-no-repeat bg-cover w-full h-[300px] sm:h-[400px] xl:h-[475px] relative">
                <svg className="z-1 fill-white absolute bottom-[-1px] " viewBox="0 0 1440 126" preserveAspectRatio="xMinYMin meet">
                    <path d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
                </svg>
            </div> */}
            <div className="bg-white pb-20 pt-40">
                <div className="max-w-[1300px] px-[5%] mx-auto">
                    {/* <div className="grid grid-cols-3 gap-4 mt-8">
                <div className="rounded-xl border border-white bg-sheer-white hover:bg-sheer-blue hover:border-mb-blue  p-8">
                    <span className="font-bold text-lg">Monthly Plan</span>

                </div>
            </div> */}

                    <div className="flex flex-col-reverse lg:flex-row lg:mt-10 gap-x-32">
                        <div className="max-w-[700px]">
                            <div className="accent-bar" />
                            <h3 className="heading">Sign Up</h3>
                            <p className=" font-bold tracking-wide pb-10">Simply send us your information so we can verify your NRDS membership. Once verified, you will be able to log in and complete payment.</p>

                            <form className="max-w-[500px]" onSubmit={submitForm}>
                                <div className="form-item">
                                    <label htmlFor="name" className="form-label">Name*</label>
                                    <input name="name" type="text" value={formInput.name} className={`form-input ${trigger && !validated.current.name ? 'error-border' : ''}`} onChange={(e) => updateFormInput("name", e.target.value)} placeholder="Contact Name"></input>
                                </div>
                                <div className="flex flex-col sm:flex-row gap-x-10">
                                    <div className="form-item">
                                        <label htmlFor="email" className="form-label">Email*</label>
                                        <input name="email" type="text" value={formInput.email} className={`form-input ${trigger && !validated.current.email ? 'error-border' : ''}`} onChange={(e) => updateFormInput("email", e.target.value)} placeholder="Email Address"></input>
                                    </div>
                                    <div className="form-item">
                                        <label htmlFor="number" className="form-label">Number</label>
                                        <input name="number" type="tel" value={formInput.phone_number} className="form-input" onChange={(e) => updateFormInput("phone_number", e.target.value)} placeholder="Phone Number"></input>
                                    </div>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="company" className="form-label">Company Name*</label>
                                    <input name="company" type="text" value={formInput.company} className={`form-input ${trigger && !validated.current.company ? 'error-border' : ''}`} onChange={(e) => updateFormInput("company", e.target.value)} placeholder="Company Name"></input>
                                </div>
                                <div className="form-item">
                                    <label htmlFor="nrds" className="form-label">NRDS Number*</label>
                                    <input name="nrds" type="text" value={formInput.nrds} className={`form-input ${trigger && !validated.current.nrds ? 'error-border' : ''}`} onChange={(e) => updateFormInput("nrds", e.target.value)} placeholder="NRDS Number"></input>
                                </div>
                                {loadedCaptcha &&
                                    <ReCAPTCHA
                                        className="pb-3"
                                        theme="light"
                                        sitekey={'6Ledg9wpAAAAAIBXbivSs68hI-UgLv0JGI_jCInP'}
                                        ref={captchaRef}
                                        onChange={null}
                                        asyncScriptOnLoad={null}
                                    />
                                }
                                <button type="submit" className="btn-primary block">Send</button>
                            </form>
                        </div>
                        {/* <div className="lg:self-start max-w-[400px] lg:max-w-none mt-16 rounded-xl bg-sheer-blue flex flex-col lg:flex-row gap-y-3 gap-x-4  pt-2 pb-8 px-8">
                            <div className=" flex flex-col gap-y-5  ">
                                <span className="font-bold heading ">Pricing</span>
                                <div className=" pricing-grid lg:gap-x-8 xl:gap-x-20 py-2 px-4 rounded-lg  bg-sheer-white " >
                                    <span className="font-bold text-lg text-nowrap"><FontAwesomeIcon icon={faCalendar} className="pr-2 text-mb-blue" />Monthly</span>
                                    <span className="font-semibold text-xl">$22</span>
                                </div>
                                <div className=" pricing-grid lg:gap-x-8 xl:gap-x-20 py-2 px-4 rounded-lg  bg-sheer-white " >
                                    <span className="font-bold text-lg text-nowrap"><FontAwesomeIcon icon={faCalendar} className="pr-2 text-mb-blue" />Quarterly</span>
                                    <span className="font-semibold text-xl">$43</span>
                                </div>
                                <div className=" pricing-grid lg:gap-x-8 xl:gap-x-20 py-2 px-4 rounded-lg  bg-sheer-white " >
                                    <span className="font-bold text-lg text-nowrap"><FontAwesomeIcon icon={faCalendar} className="pr-2 text-mb-blue" />Yearly</span>

                                    <span className="font-semibold text-xl">$134</span>
                                </div>


                            </div>
                            <div className="flex-end py-2 px-4 bg-white rounded-full shadow-sm self-end">
                                <span className="text-nowrap">Best Value!</span></div>


                        </div> */}
                    </div>
                </div>
            </div>
            {popup &&
                <>
                    <div className="backdrop" />
                    <div className="popup">
                        <h5 className="subheading">{popup.subject}</h5>
                        <p className="font-semibold tracking-wide">{popup.message}</p>
                        <button onClick={() => setPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}

        </PageWrap>
    )
}