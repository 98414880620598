import PageWrap from "./pagewrap.componant"
import handshake from "../assets/images/BuisinessHandShake-min.jpg"

import img2 from "../assets/images/SmillingBuisinessWoman-min.jpg"
import img1 from "../assets/images/BuisinessManSmilling-min.jpg"
import img3 from "../assets/images/BusinessWomanWithGlasses-min.jpg"
export default function About() {
    return (
        <PageWrap>
            <div className="relative bg-hero-pattern bg-no-repeat bg-cover min-h-[400px]">
            <div className="relative flex justify-center gap-x-40 flex-col-reverse lg:flex-row max-w-[1000px] mx-auto px-[10%] 2xl:px-0">
                    <div className="mt-6 md:w-[450px] md:h-[450px] w-full aspect-square  max-w-[450px] overflow-hidden rounded-tl-full rounded-tr-full flex-none">
                        <img  className=" h-full w-full object-cover object-0-75" src={handshake} alt="People shaking hands" />
                    </div>
                    <div className="pt-14 lg:py-40 lg:min-w-[500px]">
                        <div className="h-2 w-32 bg-logo-pink "></div>
                        <h2 className="mt-5 font-header font-bold text-3xl text-black pb-6 tracking-wider">Your <span className="font-script text-5xl pr-1">Friendly</span> Alternative</h2>
                      
                    </div>
                
                </div>
                <svg className="z-1 fill-white absolute bottom-[-1px] -scale-x-[1]" viewBox="0 0 1440 126" preserveAspectRatio="xMinYMin meet">
                    <path d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z" />
                </svg>
            </div>
            <div className="bg-white ">
                <div className="max-w-[1300px] px-[5%] mx-auto lg:pr-96 pb-20 pt-4">
                    <h2 className=" pl-4 font-header font-bold text-3xl text-black pb-6 pt-7 md:pt-0 tracking-wider">A Brief History</h2>
                    <p className="pl-4 mt-1 font-semibold tracking-wide leading-relaxed">
                        MBAIForms™ was first conceived in the 1990s to simplify preparing forms for real estate transactions. In those days, everything was done on stand-alone PCs. The software and updates were distributed on floppy discs.
                        <br /><br />
                        In the early 2000s, the system was converted to a web based system. The goal then - and now - was to keep the user interface as simple as possible.
                        <br /><br />
                        We continue to maintain the philosophy of keeping it as simple as possible and that the customer comes first.
                    </p>
                </div>

            </div>
            <div className="bg-white">
                <div className="max-w-[1300px] mx-auto px-[5%] ">


                    <div className="h-2 w-32 bg-logo-pink ml-4 "></div>
                    <h3 className="pl-4 font-header text-black font-bold  tracking-wider text-3xl mt-5 pb-6">Our Values</h3>
                    
                    <div className="pl-4 mt-8 pb-8">

                        <div className="flex flex-col md:flex-row gap-x-12 gap-y-6">
                            <div className="w-48 rounded-full h-48 overflow-hidden flex-none">
                                <img className="w-full h-full object-cover" src={img1} alt="Woman smiling with arms crossed" />

                            </div>
                            <div className="flex flex-col gap-4 justify-center ml-3 md:ml-0">
                                <h4 className="text-black tracking-wide font-bold text-xl">Customer Service</h4>
                                <p className="mt-1 font-semibold tracking-wide leading-relaxed max-w-xl">We are always available to help with a smile&#8212;our promise to our clients</p>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-x-12 gap-y-6 mt-8">
                            <div className="w-48 rounded-full h-48 overflow-hidden flex-none">
                                <img className="w-full h-full object-cover" src={img2} alt="Woman smiling with arms crossed" />

                            </div>
                            <div className="flex flex-col gap-4 justify-center ml-3 md:ml-0">
                                <h4 className="text-black tracking-wide font-bold text-xl">Simplicity</h4>
                                <p className="mt-1 font-semibold tracking-wide leading-relaxed max-w-xl">It's important to us to keep things simple and easy to use so you can focus on what matters&#8212;closing the deal</p>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-x-12 gap-y-6 mt-8">
                            <div className="w-48 rounded-full h-48 overflow-hidden flex-none">
                                <img className="w-full h-full object-cover" src={img3} alt="Man smiling with arms crossed" />

                            </div>
                            <div className="flex flex-col gap-4 justify-center ml-3 md:ml-0">
                                <h4 className="text-black tracking-wide font-bold text-xl">Continuous improvement</h4>
                                <p className="mt-1 font-semibold tracking-wide leading-relaxed max-w-xl">We are constantly working to improve MBAI Forms and adding new and updated forms each and every month.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </PageWrap>
    )
}