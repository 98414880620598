import React, { useState, useEffect } from "react";
import PageWrap from "./pagewrap.componant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export default function Faq() {
    const questions = [
        { q: 'How do I use this?', a: 'You just do' }, 
        { q: 'why?', a: 'because' }
    ]
    const [open, setOpen] = useState(new Array(questions.length).fill(false))
    return (
        <PageWrap>
            <div className="max-w-[1200px] px-[5%] mx-auto relative mb-20 ">

                <div className="accent-bar" />
                <h4 className="heading mb-4">Frequently Asked Questions</h4>
                <div className="flex flex-col gap-y-3">
                    {questions.map((question, index) =>
                        <div key={index} onClick={() => { var temp = [...open]; temp[index] = !temp[index]; setOpen(temp) }} >
                            <div className="flex justify-between py-4 px-3 cursor-pointer bg-lightest-grey items-center ">
                                <span className="font-semibold">{question.q}</span>
                                <FontAwesomeIcon className="fa-lg" transform={{ rotate: open[index] ? 180 : 0 }} icon={faAngleDown}/>

                            </div>
                            {open[index] ? 
                            <div className=" py-4 px-3 cursor-pointer bg-off-white">
                                <span>{question.a}</span>
                            </div> : null}


                        </div>)}
                        <div className="py-4 px-3 mt-3">
                            <span className="font-bold">Don't see your question answered? <br/><br/></span>
                            <span className="font-semibold  "> Call our support line at 703 349-6405 for more information</span>

                        </div>

                </div>
            </div>

        </PageWrap>
    )
}