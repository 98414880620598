import { Link } from "react-router-dom"
import PageWrap from "./pagewrap.componant"

export default function Tos() {
    return (
        <PageWrap>
            <div className="max-w-[1200px] px-[5%] mx-auto relative mb-20 ">

                <div className="accent-bar" />
                <h4 className="heading mb-4">Terms of Service</h4>
              
                Effective Date: June 1st, 2023
                <br /><br /><br />
                Please read these Terms of Service carefully before using the MBAIForms™ website. These Terms govern your use of the Website and its services.
                <br /><br />
                By accessing or using the Website, you agree to be bound by these Terms. If you disagree with any part of the Terms, please refrain from using our services.
                <br /><br />
                <span className='font-semibold'>1. Website Services</span> <br /><br />
                MBAIForms provides access to forms needed by realtors in the state of Maryland. More information about our services can be found <Link className="underline" to="/about">here.</Link>
                <br /><br />
                <span className='font-semibold'>2. User Accounts </span><br /><br />
                To access certain features or services on the Website, users are required to create an account. You are responsible for maintaining the confidentiality of your account information, including your username and password. Any activities that occur under your account are your responsibility. Please ensure that the information provided during registration is accurate and up to date.
                <br /><br />
                <span className='font-semibold'>3. User-Generated Content </span><br /><br />
                Users may have the ability to submit or upload content to the Website. By submitting or uploading content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, distribute, and display the content in connection with our services. However, the content shared by users will only be visible to the intended recipients as specified by the user.
                <br /><br />
                <span className='font-semibold'>4. Intellectual Property</span> <br /><br />
                The content, features, and functionality available on the Website are owned by or licensed to us and are protected by intellectual property laws. All trademarks, service marks, logos, and trade names displayed on the Website are proprietary to us or their respective owners. Unauthorized use of any intellectual property on the Website is strictly prohibited.
                <br /><br />
                <span className='font-semibold'>5. Privacy Policy</span> <br /><br />
                We are committed to protecting your privacy. Please review our Privacy Policy, which is incorporated by reference into these Terms, to understand how we collect, use, and protect your personal information.
                <br /><br />
                <span className='font-semibold'>6. Prohibited Activities</span> <br /><br />
                You agree not to engage in any of the following activities while using the Website:
                <br /><br />
                Hacking or otherwise attempting to gain unauthorized access to the Website or its servers. <br />
                Transmitting or distributing viruses, worms, or any other malicious code. <br />
                Spamming, including sending unsolicited communications or advertising. <br />
                Engaging in any illegal activities or encouraging others to do so. <br />
                Violating the intellectual property rights of others. <br />
                Interfering with the functionality of the Website or the experience of other users. <br /><br/>
                <span className='font-semibold'>7. User Conduct</span> <br /><br />
                You are expected to conduct yourself in a responsible and respectful manner while using the Website. This includes complying with all applicable laws and regulations and refraining from engaging in any activities that may disrupt the Website's operation or the experience of other users.
                <br /><br />
                <span className='font-semibold'>8. Dispute Resolution </span><br /><br />
                If any dispute arises between you and MBAIForms™, we encourage you to reach out to our support team to seek resolution. We will make reasonable efforts to address your concerns promptly.
                <br /><br />
                <span className='font-semibold'>9. Termination</span> <br /><br />
                We reserve the right to terminate user accounts or suspend access to the Website at our discretion and without prior notice, including for violations of these Terms or for any other reason.
                <br /><br />
                <span className='font-semibold'>10. Limitation of Liability</span> <br /><br />
                To the maximum extent permitted by law, MBAIForms™ and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use or inability to use the Website or its services.
                <br /><br />
                <span className='font-semibold'>11. Governing Law</span> <br /><br />
                These Terms shall be governed by and construed in accordance with the laws of the United States. Any legal action or proceeding arising out of or relating to these Terms shall be exclusively brought in the federal or state courts located in Toronto Ontario, and you consent to the personal jurisdiction of such courts.
                <br /><br />
                </div>
                </PageWrap>
    )
}