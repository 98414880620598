import PageWrap from "./pagewrap.componant"

export default function PrivacyPolicy() {
    return (
        <PageWrap>
            <div className="max-w-[1200px] px-[5%] mx-auto relative mb-20 ">

                <div className="accent-bar" />
                <h4 className="heading mb-4">Privacy Policy</h4>
                Last updated: June 1st 2023<br /><br/>
            <br />This Privacy Policy outlines how MBAIForms™ collects, uses, discloses, and protects the personal information of users when you access and use the MBAIForms™ website and our services.<br />
            <br /><span className='font-bold'>Information We Collect</span><br /><br />
            We may collect the following types of information when you use our Website:<br />
            <br />- Personal Information: When you create an account, we may collect your name, email address, contact information, and other details you provide.<br />
            <br />- Usage Information: We may collect information about your interactions with the Website, including your IP address, browser type, operating system, device information, and the pages or features you access.<br />
            <br /><span className='font-bold'>Use of Information</span><br /><br />
            We may use the collected information for the following purposes:<br />
            <br />- To provide and personalize our services, including facilitating user accounts and delivering requested services.<br />
            <br />- To communicate with you, respond to inquiries, and provide customer support.<br />
            <br />- To improve and optimize the Website's functionality, user experience, and services.<br />
            <br />- To analyze usage patterns, monitor trends, and gather statistical information to enhance our services.<br />
            <br />- To protect the security and integrity of our Website and prevent fraud or other unauthorized activities.<br />
            <br /><span className='font-bold'>Disclosure of Information</span><br /><br />
            We may disclose your personal information in the following circumstances:<br />
            <br />- Service Providers: We may share your information with trusted third-party service providers who assist us in operating the Website and delivering our services. These providers are obligated to protect your information and can only use it for the purposes specified by us.<br />
            <br />- Legal Compliance: We may disclose information if required by law, regulation, legal process, or governmental request.<br />
            <br />- Protection of Rights: We may disclose information to protect the rights, safety, or property of our users, ourselves, or others.<br />
            <br />- Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction, subject to customary confidentiality obligations.<br />
            <br /><span className='font-bold'>Data Retention</span><br /><br />
            We retain your personal information for as long as necessary to fulfill the purposes outlined in this Policy, unless a longer retention period is required or permitted by law.<br />
            <br /><span className='font-bold'>Third-Party Links</span><br /><br />
            Our Website may contain links to third-party websites or services. We have no control over the privacy practices or content of these third-party websites. We encourage you to review the privacy policies of those websites before providing any personal information.<br />
            <br /><span className='font-bold'>Security</span><br /><br />
            We take reasonable measures to protect the confidentiality, integrity, and security of your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee absolute security, and you acknowledge and accept any associated risks.<br />
            <br /><span className='font-bold'>Children's Privacy</span><br /><br />
            The Website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided us with personal information without your consent, please contact us to request its removal.<br />
            <br /><span className='font-bold'>Your Rights</span><br /><br />
            You have the right to access, update, correct, or delete your personal information. If you wish to exercise any of these rights, please contact us using the information provided below. We will respond to your request within a reasonable timeframe.<br />
            <br /><span className='font-bold'>Changes to the Privacy Policy</span><br /><br />
            We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any material changes through the Website or by other means. Your continued use of the Website after such modifications will constitute your acknowledgment and acceptance of the updated Policy.<br />
            <br /><span className='font-bold'>Contact Us</span><br /><br />
            If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at:<br /><br/>
            {/* <br />MBAIForms™<br /> */}
         
            
            703 349-6405<br /> 
            </div>
        </PageWrap>
    )
}