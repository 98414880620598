

const regValidate = (key, value) => {
    switch (key) {
        case ("email"):
            return String(value)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );


        case ("password"):

            return String(value)
                .toLowerCase()
                .match(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,64}$/
                );


        case ("name"):
            return String(value).toLowerCase().match(/^[A-Za-z'\.\-\s\,]{3,50}$/);


        case ("phone_number"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[0-9]{10,10}$/
                );


        case ("address"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9'\.\-\s\,]{5,100}$/
                );


        case ("city"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z'\.\-\s\,]{2,100}$/
                );


        case ("zip_code"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9 ]{5,11}$/
                );


        case ("subject"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9 ]{2,100}$/
                );


        case ("message"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9 ]{2,1000}$/
                );
        case ("company"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9 ]{2,1000}$/
                );
        case ("nrds"):
            return String(value)
                .toLowerCase()
                .match(
                    /^[A-Za-z0-9 ]{5,1000}$/
                );



    }
    return false;
}


const validateUtility = {
    regValidate
}

export default validateUtility;