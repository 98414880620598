import PageWrap from "./pagewrap.componant"
import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import boardMeeting from "../assets/images/boardmeeting.jpg"
import beach2024 from "../assets/images/beach2024.jpg"
import marLogo from "../assets/images/logos/expanded-mar-logo.png"
import img from "../assets/images/stock-photo-a-couple-sitting-at-cafe-laughing-cheerfully-pretty-girl-is-pointing-at-the-laptop-screen-1034001967.jpg"
export default function Announcements() {
    const [updatesOpen, setUpdatesOpen] = useState(false)
    const [announcementsOpen, setAnnouncementsOpen] = useState(false)

    const announcements = [
        { img: beach2024, date: 'September 14, 2024', message: 'We will be attending Maryland REALTORS 2024 Annual Convention By the Beaches from Monday, September 16th - Thursday, September 19th. Please stop by booth 41, lets talk about real estate and how MBAIForms can help you.' },    
        { img: boardMeeting, date: "July 13, 2024", message: "We are attending the GBBR REALTOR Fair in October 17th. Feel free to come by and we would love to explain how MBAI Forms can help you!" },
    ]

    const updates = [
    { date: 'October 1, 2024', message: 'All the new forms are updated and live on the system.' },
    { date: 'August 30, 2024', message: 'All the new forms have been updated and are live in the system.' },
    { date: 'July 20, 2024', message: 'We are currently working on the new forms. The following forms are already live: The buyer agreement amendment, the listing agreement amendment, and the listing broker’s offer of cooperative compensation.' },
    { date: 'May 20, 2024', message: 'Updated several outdated doccuments' },
    { date: 'October 2, 2023', message: 'Maryland REALTORS® OCTOBER 2023 STATEWIDE FORMS SUMMARY OF CHANGES AND PRACTICE TIPS' },
    { date: 'January 31, 2023', message: 'Maryland REALTORS® JANUARY 2023 STATEWIDE FORMS Updates completed' },
    { date: 'October 1, 2022', message: 'Maryland REALTORS® 2022 Summary of Changes & Practice Tips' },
    { date: 'July 16, 2022', message: "NEW FEATURE: You can now put free form text on your cover page - an additional edit control screen will come up. Leave it blank if you don't want to use this feature." },
    { date: "October 1, 2021", message: "Maryland REALTORS® 2021 Summary of Changes & Practice Tips" },
    { date: "October 9, 2020", message: "Added new AUTOSAVE feature" },
    { date: "October 1, 2020", message: "Maryland Realtors® issued new versions of existing documents and one new document. These updates have been installed and are described in the “Announcements” section of the Document Categories screen, the first screen after you login." },
    { date: "April 13, 2020", message: "Maryland Realtors® issued two new COVID-19 Addenda. These were added to the Exclusive Right to Sell and The Exclusive Right to Lease sections." },
    { date: "March 19, 2020", message: "Maryland Realtors® issued a new document ‘COVID-19 Related Addendum’. This document was added to the Residential Contract of Sale and Exclusive Right to Sell Brokerage Agreement." },
    { date: "January 1, 2020", message: "Maryland Realtors® issued new versions of existing document. These updates have been installed and are described in the “Announcements” section of the Document Categories screen, the first screen after you login." },
    { date: "October 1, 2019", message: "Maryland Realtors® issued new versions of existing documents and 5 new documents. These updates have been installed and are described in the “Announcements” section of the Document Categories screen, the first screen after you login." },
    { date: "October 1, 2018", message: "2018 Maryland Realtors® issued new versions of existing documents and 3 new documents and removed 2 documents. These updates have been installed and are described in the “Announcements” section of the Document Categories screen, the first screen after you login." },
    { date: "October 1, 2017", message: "The Maryland Association of Realtors® issued new versions of existing documents and 3 new documents. These updates have been installed and are described in the “Announcements” section of the Document Categories screen, the first screen after you login." },
    { date: "October 1, 2016", message: "The Maryland Association of Realtors® issued new versions of existing documents and 4 new documents. These updates have been installed and are described in the “Announcements” section of the Document Categories screen, the first screen after you login." },
    { date: "June 15, 2016", message: "MBAIForms™ will begin using on-line bill payments, enabling you to pay your subscription on-line. This new feature will eliminate invoices and streamline the payment process so you can focus on your business." }
    ]
    return (
        <PageWrap>
            <div className="max-w-[1200px] px-[5%] mx-auto relative mb-20 ">

                <div className="accent-bar" />
                <h4 className="heading mb-4">Announcements</h4>

                <div className={`grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 ${announcements?.length > 6 ? 'mask-30' : ''} `}>
                    {announcements.splice(0, announcementsOpen ? announcements?.length : 6).map((announ, index) =>
                        <div key={index} style={{ gridTemplateRows: "250px auto " }} className="rounded-lg grid gap-y-3 bg-[#f9f9f9] shadow-sm group ">
                            <div className="rounded-t-lg overflow-hidden ">
                                <img className="h-full w-full group-hover:scale-[1.03] duration-point-five no-blur object-cover rounded-t-lg" src={announ.img} alt={announ.date} />
                            </div>
                            <div className="px-10 pb-5 flex flex-col gap-y-3 py-3">
                                <span className="font-bold text-dark-grey ">{announ.date}</span>
                                <span className="font-semibold">{announ.message}</span>
                                {announ.link && announ.linkText ? 
                                <a className="py-3 hover:text-dark-blue" href={announ.link}>{announ.linkText}<FontAwesomeIcon className="pl-2" icon={faArrowRight}/></a>
                                : null }
                            </div>

                        </div>
                    )}


                </div>
                {announcements?.length > 6 ? 
                <button onClick={() => setAnnouncementsOpen(!announcementsOpen)} className="bg-white hover:shadow-md duration-point-three rounded-lg px-3 py-1.5 cursor-pointer shadow-sm mx-auto w-fit block">Show {announcementsOpen ? 'Less' : 'More'} <FontAwesomeIcon transform={{ rotate: announcementsOpen ? 180 : 0 }} icon={faAngleDown} /></button>
                    : null }
            </div>


            <div className="max-w-[1200px] px-[5%] mx-auto relative mb-20 ">
                <div className="relative">
                    <div className="accent-bar" />
                    <h4 className="heading mb-4">Site Updates</h4>

                    <div>
                        {updates.splice(0, updatesOpen ? updates?.length : 6).map((update, index) =>
                            <div key={index} className={`${updatesOpen ? '' : 'masked'} relative border-l border-med-grey pt-5 pb-5 first:pt-0 group duration-point-five ml-16 xl:ml-0 `}>
                                <div className="absolute rounded-full bg-med-grey py-1 px-2 -translate-x-1/2 group-hover:bg-black group-hover:shadow-lg group-hover:px-2.5 group-hover:py-1.5 group-hover:-translate-y-0.5 duration-point-five">
                                    <span className="text-white text-sm">{update.date}</span>

                                </div>
                                <span className="pt-16 pl-4 sm:pl-24 sm:pt-0 block group-hover:text-black no-blur font-semibold duration-point-five group-hover:translate-x-3 ">{update.message}</span>

                            </div>)}
                    </div>
                    <div className="bg-med-grey absolute rounded-full h-2 w-2 bottom-0 -translate-x-1/2 ml-16 xl:ml-0" />

                </div>
                <button onClick={() => setUpdatesOpen(!updatesOpen)} className="bg-white hover:shadow-md duration-point-three rounded-lg px-3 py-1.5 cursor-pointer shadow-sm mx-auto w-fit block">Show {updatesOpen ? 'Less' : 'More'} <FontAwesomeIcon transform={{ rotate: updatesOpen ? 180 : 0 }} icon={faAngleDown} /></button>
            </div>

        </PageWrap>
    )
}