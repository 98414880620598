import React, { useEffect, useState } from "react";

export default function PageWrap({children}) {
    const [padding, setPadding] = useState(100)
    useEffect(() => {
        document.addEventListener("scroll", () => {


            setPadding(Math.max(100 - window.scrollY, 70))
          
        })

    }, [])
    return (
        <div className="min-h-[85vh]" style={{paddingTop: padding, transition: 'all 1s ease-in-out' }} >
            {children}

        </div>
    )
}