import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faFacebookSquare, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
    const [privacyPopup, setPrivacyPopup] = useState(false)
    return (
        <div className="bg-med-grey ">
            <div className=" text-white flex flex-col md:flex-row py-8 px-10 tracking-wide gap-x-40 gap-y-10">
                <div className="flex flex-col md:flex-row gap-x-20 gap-y-10">
                    <div className="flex flex-col gap-5">
                        <h5 className="font-bold tracking-wider text-lg">Company</h5>
                        <div className="flex flex-col gap-2">
                            <Link to={"/about"}>About Us</Link>
                            <Link to={"/contact"}>Contact</Link>
                            <Link to={"/subscribe"}>Subscribe</Link>
                            <Link to={"/announcements"}>Announcements</Link>
                        </div>

                    </div>

                    <div className="flex flex-col gap-5">
                        <h5 className="font-bold tracking-wider text-lg">Support</h5>
                        <div className="flex flex-col gap-2">
                            {/* <Link to={"/faq"}>Frequently Asked Questions</Link> */}
                            <Link to={"/terms-of-service"}>Terms of Service</Link>
                            <Link to={"/privacy-policy"}>Privacy Policy</Link>
                        </div>

                    </div>

                </div>
                <div>
                    <h5 className="font-bold tracking-wider text-lg pb-5">Follow Us</h5>
                    <div className="flex gap-x-4 text-xl">
                        <a href={"https://www.facebook.com"}>
                            <FontAwesomeIcon icon={faFacebookSquare} />
                        </a>
                        <a href={"https://www.facebook.com"}>
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href={"https://www.youtube.com/channel/UCAY-09bgzgzKhqlW8THqgkw"}>
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                        <a href={"https://x.com/mbaiforms39598"}>
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>

                    </div>
                </div>


            </div>
            <div className="border-t text-[#dfdfdf] border-[#cfcfcf] mx-3 px-7 py-2 text-sm flex justify-between">
                <span>© 2024 by M&A Holdings Inc.</span>
                <span onClick={()=>setPrivacyPopup(true)} className="cursor-pointer">We Do Not Sell or Share Your Data</span>

            </div>
            {privacyPopup &&
                <>
                    <div className="backdrop" />
                    <div style={{zIndex:'60'}} className="popup">
                        <h5 className="subheading">Your Data is Safe With Us</h5>
                        <p className="font-semibold tracking-wide">We will never sell your information to anyone, and only share 
                            data with trusted third-party service providers whose services we use in operating the website. <br/><br/>
                            By using this site, you consent to the use of necessary cookies needed to provide our services.
                             </p>

                             <a className="underline" href="/privacy-policy">Read our full privacy policy here</a>
                        <button onClick={() => setPrivacyPopup(false)} className="btn-primary">Close</button>
                    </div>
                </>}
        </div>
    )
}